<template>
  <v-card>
    <v-card-title class="align-start">
      <span class="font-weight-semibold text-base">Statistiques</span>
      <dag-info
        :dags="[
          'ga_get_and_store_site_daily_dag',
          'partner_get_and_store_daily_dag',
          'partner_get_and_store_daily_ifempty_dag',
        ]"
      />
    </v-card-title>
    <loader v-if="loading" />
    <v-card-text v-else>
      <v-row>
        <v-col cols="12" md="3">
          <div class="d-flex">
            <v-avatar size="44" color="primary" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiCurrencyEur }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">CA</p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text--primary text-xl font-weight-bold"
                    v-on="on"
                    v-bind="attrs"
                    >{{ ca.value }}</span
                  >
                </template>
                <span>{{ ca.raw }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <div class="d-flex">
            <v-avatar size="44" color="primary" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiTarget }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Objectif CA</p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text--primary text-xl font-weight-bold"
                    v-on="on"
                    v-bind="attrs"
                    >{{ objectif.value }}</span
                  >
                </template>
                <span>{{ objectif.raw }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <div class="d-flex">
            <v-avatar size="44" color="primary" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiPercent }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Ratio CA/Objs</p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text--primary text-xl font-weight-bold"
                    v-on="on"
                    v-bind="attrs"
                    >{{ ratio.value }}</span
                  >
                </template>
                <span>{{ ratio.raw }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <div class="d-flex">
            <v-avatar size="44" color="primary" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiSpeedometer }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">RPM</p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text--primary text-xl font-weight-bold"
                    v-on="on"
                    v-bind="attrs"
                    >{{ rpm.value }}</span
                  >
                </template>
                <span>{{ rpm.raw }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <div class="d-flex">
            <v-avatar size="44" color="primary" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiCashMultiple }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Estimation CA</p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text--primary text-xl font-weight-bold"
                    v-on="on"
                    v-bind="attrs"
                    >{{ estiCa.value }}</span
                  >
                </template>
                <span>{{ estiCa.raw }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <div class="d-flex">
            <v-avatar size="44" color="primary" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiSackPercent }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Ratio CA/Estimations</p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text--primary text-xl font-weight-bold"
                    v-on="on"
                    v-bind="attrs"
                    >{{ estiRatio.value }}</span
                  >
                </template>
                <span>{{ estiRatio.raw }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <div class="d-flex">
            <v-avatar size="44" color="primary" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiAccount }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Sessions</p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text--primary text-xl font-weight-bold"
                    v-on="on"
                    v-bind="attrs"
                    >{{ session.value }}</span
                  >
                </template>
                <span>{{ session.raw }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="3">
          <div class="d-flex">
            <v-avatar size="44" color="primary" rounded class="elevation-1">
              <v-icon dark color="white" size="30">
                {{ icons.mdiTargetAccount }}
              </v-icon>
            </v-avatar>
            <div class="ms-3">
              <p class="text-xs mb-0">Objectif sessions</p>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span
                    class="text--primary text-xl font-weight-bold"
                    v-on="on"
                    v-bind="attrs"
                    >{{ sessionObjective.value }}</span
                  >
                </template>
                <span>{{ sessionObjective.raw }}</span>
              </v-tooltip>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  mdiSpeedometer,
  mdiPercent,
  mdiTarget,
  mdiCurrencyEur,
  mdiCashMultiple,
  mdiSackPercent,
  mdiAccount,
  mdiTargetAccount,
} from "@mdi/js";
import { cacheTime } from "@axios";

import DagInfo from "@/components/common/menus/DagInfo";
import Loader from "@/components/common/Loader";
import useAxios from "@/hooks/useAxios";
import { runParallelAsyncs } from "@/utils/async";
import {
  formatCurrencyCompact,
  formatNumberCompact,
  formatPercent,
} from "@/utils/formatting";

export default {
  name: "TopStatsCard",
  components: {
    DagInfo,
    Loader,
  },
  data() {
    return {
      loading: false,
      icons: {
        mdiCurrencyEur,
        mdiTarget,
        mdiPercent,
        mdiCashMultiple,
        mdiSackPercent,
        mdiSpeedometer,
        mdiAccount,
        mdiTargetAccount,
      },
      ca: {
        value: 0,
        raw: 0,
      },
      objectif: {
        value: 0,
        raw: 0,
      },
      ratio: {
        value: 0,
        raw: 0,
      },
      rpm: {
        value: 0,
        raw: 0,
      },
      estiCa: {
        value: 0,
        raw: 0,
      },
      estiRatio: {
        value: 0,
        raw: 0,
      },
      session: {
        value: 0,
        raw: 0,
      },
      sessionObjective: {
        value: 0,
        raw: 0,
      },
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    async getData() {
      if (
        this.applyFilters &&
        !this.loading &&
        this.month &&
        this.month.length > 5
      ) {
        const queryParams = {
          month: this.month,
          ...(this.sites && this.sites.length > 0
            ? { sites: this.sites.join(",") }
            : {}),
          ...(this.partnerViews && this.partnerViews.length > 0
            ? { partner_views: this.partnerViews.join(",") }
            : {}),
        };

        this.loading = true;
        const {
          responseRevenue: { data: revenueData },
          responseAudience: { data: audienceData },
        } = await runParallelAsyncs({
          responseRevenue: this.axiosGet(
            "/programmatic/total-monthly-report",
            queryParams,
            {
              cache: {
                maxAge: cacheTime,
              },
            }
          ),
          responseAudience: this.axiosGet(
            "/programmatic/monthly-report-audience",
            queryParams,
            {
              cache: {
                maxAge: cacheTime,
              },
            }
          ),
        });

        this.estiCa.value = formatCurrencyCompact(
          revenueData["ca_estimate_eur"]
        );
        this.estiCa.raw = revenueData["ca_estimate_eur"];
        this.ca.value = formatCurrencyCompact(revenueData["current_ca"]);
        this.ca.raw = revenueData["current_ca"];
        this.objectif.value = formatCurrencyCompact(
          revenueData["current_ca_objective"]
        );
        this.objectif.raw = revenueData["current_ca_objective"] * 100;
        this.ratio.value = `${(
          (revenueData["current_ca"] / revenueData["current_ca_objective"]) *
          100
        ).toFixed(2)}%`;
        this.ratio.raw = this.ratio.value;
        this.rpm.value = `${(
          (revenueData["current_ca"] / audienceData["sessions"]) *
          1000
        ).toFixed(2)}€`;
        this.rpm.raw = this.rpm.value;
        this.estiRatio.value = formatPercent(
          revenueData["ca_estimate_perct"],
          0
        );
        this.estiRatio.raw = this.estiRatio.value;
        this.session.value = formatNumberCompact(audienceData["sessions"]);
        this.session.raw = revenueData["current_sessions"];
        this.sessionObjective.value = formatNumberCompact(
          revenueData["current_sessions_objective"]
        );
        this.sessionObjective.raw = revenueData["current_sessions_objective"];

        this.loading = false;
      }

      // Force clicking on button again to re-run that request :
      // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
      this.$store.dispatch("common/updateApplyFilters", false);
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters["general/getSites"];
    },
    month() {
      return this.$store.getters["general/getMonthDate"];
    },
    partnerViews() {
      return this.$store.getters["general/getPartnerViews"];
    },
  },
  watch: {
    applyFilters() {
      this.getData();
    },
  },
};
</script>

<style scoped></style>
