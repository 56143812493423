<template>
  <line-chart
    title="CA & Sessions vs Objectifs"
    :loading="loading"
    :colors="colors"
    :series="series"
    :yAxis="yAxis"
    :xAxis="xAxis"
    :dags="[
      'ga_get_and_store_site_daily_dag',
      'partner_get_and_store_daily_dag',
      'partner_get_and_store_daily_ifempty_dag',
    ]"
  />
</template>

<script>
import { cacheTime } from "@axios";
import moment from "moment/moment";

import LineChart from "@/components/common/charts/LineChart";
import useAxios from "@/hooks/useAxios";
import { runParallelAsyncs } from "@/utils/async";
import { colors } from "@/utils/constants";
import { getDate, getDaysArray, monthRange } from "@/utils/dates";
import { formatNumber } from "@/utils/formatting";

export default {
  name: "CaSessionsObjectivesChart",
  components: {
    LineChart,
  },
  data() {
    return {
      loading: false,
      series: [
        {
          name: "CA",
          data: [],
        },
        {
          name: "Objectif Revenue",
          data: [],
        },
        {
          name: "Sessions",
          data: [],
        },
        {
          name: "Objectif Sessions",
          data: [],
        },
      ],
      colors: [colors.ca, colors.ca, colors.sessions, colors.sessions], // "#1095f5", "#1095f5", "#ec0c32", "#ec0c32",
      stroke: {
        show: true,
        curve: "smooth",
        width: 2,
        dashArray: [0, 9, 0, 9],
      },
      yAxis: [
        {
          title: {
            text: "Revenue",
          },
          seriesName: "Revenue",
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        },
        {
          seriesName: "Revenue",
          min: 0,
          show: false,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        },
        {
          title: {
            text: "Sessions",
          },
          seriesName: "Sessions",
          opposite: true,
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        },
        {
          seriesName: "Sessions",
          show: false,
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        },
      ],
      xAxis: {
        categories: [],
      },
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    async getData() {
      if (
        this.applyFilters &&
        !this.loading &&
        this.month &&
        this.month.length > 5
      ) {
        let queryParams = {
          month: this.month,
          ...(this.sites && this.sites.length > 0
            ? { sites: this.sites.join(",") }
            : {}),
          ...(this.partnerViews && this.partnerViews.length > 0
            ? { partner_views: this.partnerViews.join(",") }
            : {}),
        };

        const range = monthRange(this.month);
        const categories = getDaysArray(range[0], range[1]);
        const mainData = {};

        const yesterday = moment(getDate(-1));
        for (const x of categories) {
          const defaultValue = moment(new Date(x)) >= yesterday ? null : 0;
          mainData[x] = {
            ca: defaultValue,
            sessions: defaultValue,
            objRevenue: defaultValue,
            objSessions: defaultValue,
          };
        }

        this.loading = true;
        const {
          detailedRevenueResponse: { data: detailedRevenueData },
          detailedAudienceResponse: { data: detailedAudienceData },
          totalResponse: { data: totalData },
        } = await runParallelAsyncs({
          detailedRevenueResponse: this.axiosGet(
            "/programmatic/detailed-revenue-monthly-report",
            queryParams,
            {
              cache: {
                maxAge: cacheTime,
              },
            }
          ),
          detailedAudienceResponse: this.axiosGet(
            "/programmatic/detailed-audience-monthly-report",
            queryParams,
            {
              cache: {
                maxAge: cacheTime,
              },
            }
          ),
          totalResponse: this.axiosGet(
            "/programmatic/total-monthly-report",
            queryParams,
            {
              cache: {
                maxAge: cacheTime,
              },
            }
          ),
        });

        delete queryParams["partner_views"];
        queryParams["objtype"] = "sessions";
        const { data: objectivesSessionsData } = await this.axiosGet(
          "/objectives/",
          queryParams,
          {
            cache: {
              maxAge: cacheTime,
            },
          }
        );

        this.xAxis = {
          ...this.xAxis,
          categories,
        };

        // align the revenue and the objective revenue on the same scale
        // using the same max value
        const getRevenueMaxValue =
          detailedRevenueData.reduce(
            (acc, curr) => (acc > curr["ca"] ? acc : curr["ca"]),
            0
          ) * 1.1;
        this.yAxis[0].max = getRevenueMaxValue;
        this.yAxis[1].max = getRevenueMaxValue;

        // align the sessions and the objective sessions on the same scale
        // using the same max values;
        const getSessionsMaxValue =
          detailedAudienceData.reduce(
            (acc, curr) => (acc > curr["sessions"] ? acc : curr["sessions"]),
            0
          ) * 1.1;
        this.yAxis[2].max = getSessionsMaxValue;
        this.yAxis[3].max = getSessionsMaxValue;

        detailedRevenueData.map((data) => {
          mainData[data["date"]]["ca"] = data["ca"];
        });
        detailedAudienceData.map((data) => {
          mainData[data["date"]]["sessions"] = data["sessions"];
        });
        const objectiveRevenueDay =
          totalData["current_ca_objective"] / categories.length;

        const objectiveSessionsMonth = objectivesSessionsData["value"];
        const objectiveSessionsDay = objectiveSessionsMonth / categories.length;

        // const objectiveRPMDay =
        //  (objectiveRevenueDay / objectiveSessionsDay) * 1000;
        for (const x of categories) {
          mainData[x]["objRevenue"] = objectiveRevenueDay;
          mainData[x]["objSessions"] = objectiveSessionsDay;
        }

        this.series = [
          {
            name: "CA",
            data: categories.map((category) => mainData[category]["ca"]),
          },
          {
            name: "Objectif CA",
            data: categories.map(
              (category) => mainData[category]["objRevenue"]
            ),
          },
          {
            name: "Sessions",
            data: categories.map((category) => mainData[category]["sessions"]),
          },
          {
            name: "Objectif Sessions",
            data: categories.map(
              (category) => mainData[category]["objSessions"]
            ),
          },
        ];

        this.loading = false;
      }

      // Force clicking on button again to re-run that request :
      // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
      this.$store.dispatch("common/updateApplyFilters", false);
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters["general/getSites"];
    },
    month() {
      return this.$store.getters["general/getMonthDate"];
    },
    partnerViews() {
      return this.$store.getters["general/getPartnerViews"];
    },
  },
  watch: {
    applyFilters() {
      this.getData();
    },
  },
};
</script>
