<template>
  <div :class="!isInTemplate ? 'pa-6 ma-4' : ''">
    <div v-if="isInTemplate">
      <p class="text-2xl mb-6">Infos générales</p>
      <filters></filters>
    </div>
    <v-row>
      <v-col cols="12">
        <top-stats-card />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <ca-sessions-objectives-chart />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <top-rpm-per-site-chart />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6">
        <rpm-objectives-chart />
      </v-col>
      <v-col cols="6">
        <c-a-distribution store="general" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <top-sites-top-partners-chart />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <site-revenue-session-rpm-table />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CADistribution from "@/components/general/dashboard/cards/CADistributionChart";
import CaSessionsObjectivesChart from "@/components/general/dashboard/cards/CaSessionsObjectivesChart";
import Filters from "@/components/general/dashboard/filters/Filters";
import RpmObjectivesChart from "@/components/general/dashboard/cards/RPMObjectivesChart";
import SiteRevenueSessionRpmTable from "@/components/general/dashboard/tables/SiteRevenueSessionRpmTable";
import TopRpmPerSiteChart from "@/components/general/dashboard/cards/TopRpmPerSiteChart";
import TopSitesTopPartnersChart from "@/components/general/dashboard/cards/TopSitesTopPartnersChart";
import TopStatsCard from "@/components/general/dashboard/cards/TopStatsCard";

import { saveRmraView } from "@/utils/rmr-analytics";

export default {
  name: "GeneralDecoupled",
  components: {
    TopSitesTopPartnersChart,
    SiteRevenueSessionRpmTable,
    CADistribution,
    Filters,
    CaSessionsObjectivesChart,
    RpmObjectivesChart,
    TopRpmPerSiteChart,
    TopStatsCard,
  },
  mounted() {
    saveRmraView(this.$route);

    this.setDefaultFilters();
  },
  methods: {
    async setDefaultFilters() {
      if (
        this.$route?.query?.partnerViews ||
        this.$route?.query?.partnerViewGroups
      ) {
        return;
      }

      const partnerViewGroups = await this.$store.dispatch(
        "common/getPartnerViewGroupList"
      );
      const exportSuiviGeneral = partnerViewGroups.find(
        (g) => g.name === "Export Suivi Général"
      );

      this.$store.dispatch(
        "general/updatePartnerViewGroups",
        exportSuiviGeneral ? [exportSuiviGeneral.id] : [] //
      );
    },
  },
  computed: {
    isInTemplate() {
      return this.$route.name === "general-dashboard";
    },
  },
};
</script>
