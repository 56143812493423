<template>
  <v-card>
    <dashboard-chart
      v-if="series[0].data.length"
      title="Top RPM par site"
      type="bar"
      :loading="loading"
      :colors="colors"
      :series="series"
      :categories="categories"
      :chartOptions="dashboardMainChartOptions"
      :dags="[
        'ga_get_and_store_site_daily_dag',
        'partner_get_and_store_daily_dag',
        'partner_get_and_store_daily_ifempty_dag',
      ]"
    ></dashboard-chart>
  </v-card>
</template>

<script>
import { cacheTime } from "@axios";

import DashboardChart from "@/components/common/charts/DashboardChart";
import useAxios from "@/hooks/useAxios";
import { colors } from "@/utils/constants";
import { formatNumber } from "@/utils/formatting";

export default {
  name: "TopRpmPerSiteChart",
  components: {
    DashboardChart,
  },
  data() {
    return {
      loading: false,
      colors: [colors.rpm],
      series: [
        {
          name: "CA",
          data: [],
        },
      ],
      categories: [],
      dashboardMainChartOptions: {
        chart: {
          height: 350,
          zoom: {
            enabled: false,
          },
        },
        colors: ["#1095f5"],
        dataLabels: {
          enabled: false,
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        yaxis: [
          {
            title: {
              text: "Revenue",
            },
            seriesName: "Revenue",
            min: 0,
            forceNiceScale: true,
            labels: {
              formatter: (val) => formatNumber(val),
            },
          },
          {
            seriesName: "Revenue",
            show: false,
            labels: {
              formatter: (val) => formatNumber(val),
            },
          },
          {
            title: {
              text: "Sessions",
            },
            seriesName: "Sessions",
            opposite: true,
            min: 0,
            forceNiceScale: true,
            labels: {
              formatter: (val) => formatNumber(val),
            },
          },
          {
            seriesName: "Sessions",
            show: false,
            labels: {
              formatter: (val) => formatNumber(val),
            },
          },
        ],
        xaxis: {
          categories: this.categories,
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    async getData() {
      if (
        this.applyFilters &&
        !this.loading &&
        this.month &&
        this.month.length > 5
      ) {
        const queryParams = {
          month: this.month,
          limit: 10,
          ...(this.sites && this.sites.length > 0
            ? { sites: this.sites.join(",") }
            : {}),
          ...(this.partnerViews && this.partnerViews.length > 0
            ? { partner_views: this.partnerViews.join(",") }
            : {}),
        };

        this.loading = true;
        const { data } = await this.axiosGet(
          "/programmatic/top-rpm-per-site",
          queryParams,
          {
            cacheTime: cacheTime,
          }
        );

        this.dashboardMainChartOptions = {
          ...this.dashboardMainChartOptions,
          xaxis: {
            categories: this.categories,
          },
        };

        this.series = [
          {
            name: "Top RPM",
            data: data.map((item) => {
              return { x: item.name, y: item.rpm };
            }),
          },
        ];

        this.loading = false;
      }

      // Force clicking on button again to re-run that request :
      // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
      this.$store.dispatch("common/updateApplyFilters", false);
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters["general/getSites"];
    },
    month() {
      return this.$store.getters["general/getMonthDate"];
    },
    partnerViews() {
      return this.$store.getters["general/getPartnerViews"];
    },
  },
  watch: {
    applyFilters() {
      this.getData();
    },
  },
};
</script>
