<template>
  <line-chart
    title="RPM vs Objectif"
    :loading="loading"
    :colors="colors"
    :series="series"
    :yAxis="yAxis"
    :xAxis="xAxis"
    :dags="[
      'ga_get_and_store_site_daily_dag',
      'partner_get_and_store_daily_dag',
      'partner_get_and_store_daily_ifempty_dag',
    ]"
  ></line-chart>
</template>

<script>
import { cacheTime } from "@axios";
import moment from "moment/moment";

import LineChart from "@/components/common/charts/LineChart";
import useAxios from "@/hooks/useAxios";
import { runParallelAsyncs } from "@/utils/async";
import { colors } from "@/utils/constants";
import { getDate, getDaysArray, monthRange } from "@/utils/dates";
import { formatNumber } from "@/utils/formatting";

export default {
  name: "RpmObjectivesChart",
  components: {
    LineChart,
  },
  data() {
    return {
      loading: false,
      series: [
        {
          name: "RPM",
          data: [],
        },
        {
          name: "Objectif",
          data: [],
        },
      ],
      colors: [colors.rpm, colors.objective], // "#F44336", "#00d4bd", "#9C27B0",
      yAxis: [
        {
          min: 0,
          forceNiceScale: true,
          labels: {
            formatter: (val) => formatNumber(val),
          },
        },
      ],
      xAxis: {
        categories: [],
      },
    };
  },
  setup() {
    const { axiosGet } = useAxios();

    return {
      axiosGet,
    };
  },
  methods: {
    async getData() {
      if (
        this.applyFilters &&
        !this.loading &&
        this.month &&
        this.month.length > 5
      ) {
        let queryParams = {
          month: this.month,
          ...(this.sites && this.sites.length > 0
            ? { sites: this.sites.join(",") }
            : {}),
          ...(this.partnerViews && this.partnerViews.length > 0
            ? { partner_views: this.partnerViews.join(",") }
            : {}),
        };

        const range = monthRange(this.month);
        const categories = getDaysArray(range[0], range[1]);
        const mainData = {};
        const yesterday = moment(getDate(-1));
        for (const x of categories) {
          const defaultValue = moment(new Date(x)) >= yesterday ? null : 0;
          mainData[x] = {
            ca: defaultValue,
            objRevenue: defaultValue,
            rpms: defaultValue,
            sessions: defaultValue,
          };
        }

        this.loading = true;
        const {
          detailedRevenueResponse: { data: detailedRevenueData },
          detailedAudienceResponse: { data: detailedAudienceData },
          totalResponse: { data: totalData },
        } = await runParallelAsyncs({
          detailedRevenueResponse: this.axiosGet(
            "/programmatic/detailed-revenue-monthly-report",
            queryParams,
            {
              cache: {
                maxAge: cacheTime,
              },
            }
          ),
          detailedAudienceResponse: this.axiosGet(
            "/programmatic/detailed-audience-monthly-report",
            queryParams,
            {
              cache: {
                maxAge: cacheTime,
              },
            }
          ),
          totalResponse: this.axiosGet(
            "/programmatic/total-monthly-report",
            queryParams,
            {
              cache: {
                maxAge: cacheTime,
              },
            }
          ),
        });

        delete queryParams["partner_views"];
        queryParams["objtype"] = "sessions";
        const { data: objectivesSessionsData } = await this.axiosGet(
          "/objectives/",
          queryParams
        );

        this.xAxis = {
          ...this.xAxis,
          categories,
        };

        detailedRevenueData.map((data) => {
          mainData[data["date"]]["ca"] = data["ca"];
        });
        detailedAudienceData.map((data) => {
          mainData[data["date"]]["sessions"] = data["sessions"];
        });
        const mainDataWithRpm = this.calculateRPM(categories, mainData);
        const objectiveRevenueDay =
          totalData["current_ca_objective"] / categories.length;

        const objectiveSessionsMonth = objectivesSessionsData["value"];
        const objectiveSessionsDay = objectiveSessionsMonth / categories.length;

        const objectiveRPMDay =
          (objectiveRevenueDay / objectiveSessionsDay) * 1000;
        for (const x of categories) {
          mainDataWithRpm[x]["objRPM"] = objectiveRPMDay;
        }

        this.series = [
          {
            name: "RPM",
            data: categories.map(
              (category) => mainDataWithRpm[category]["rpms"]
            ),
          },
          {
            name: "Objectif",
            data: categories.map(
              (category) => mainDataWithRpm[category]["objRPM"]
            ),
          },
        ];

        this.loading = false;
      }

      // Force clicking on button again to re-run that request :
      // (we don't call this dispatch earlier to be sure, as state is async, that all listening components could run their requests)
      this.$store.dispatch("common/updateApplyFilters", false);
    },
    calculateRPM(categories, mainData) {
      for (const x of categories) {
        if (mainData[x]["ca"] > 0 && mainData[x]["sessions"] > 0)
          mainData[x]["rpms"] = (
            (mainData[x]["ca"] / mainData[x]["sessions"]) *
            1000
          ).toFixed(3);
      }

      return mainData;
    },
  },
  computed: {
    applyFilters() {
      return this.$store.getters["common/getApplyFilters"];
    },
    sites() {
      return this.$store.getters["general/getSites"];
    },
    month() {
      return this.$store.getters["general/getMonthDate"];
    },
    partnerViews() {
      return this.$store.getters["general/getPartnerViews"];
    },
  },
  watch: {
    applyFilters() {
      this.getData();
    },
  },
};
</script>
